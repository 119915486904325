import React, {useRef} from 'react'
import styled, {keyframes} from 'styled-components'
import { useScroll }from 'framer-motion'
import { useEffect } from 'react';
import { useState } from 'react';

export const Screen = ({text, background, image, percent, handleClick, index, setActive, isLast}) => {
  const ref = useRef(null);
  const [progress, setProgress] = useState(0);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "start start"]
  });
  useEffect(() => {
    const trackChange = latest => {
      setProgress(latest);
      if(latest > 0.1 && latest < 0.7) {
        setActive(index)
      } else if(latest > 0.7 && isLast) {
        setActive(index + 1);
      }
    }
    return scrollYProgress.onChange(trackChange)
  }, [])

  const formatPercent = (progress) => {
    if(progress < 0.2) {
      const modifier = progress / 0.2;
      return `${(percent * modifier).toFixed(1)}%`;
    }
    return `${percent.toFixed(1)}%`;
  }
  return (
    <Placeholder onClick={handleClick}>
      {image && <Image src={image} style={{'--progress': progress}} />}
      {background && <Background color={background} style={{'--progress': progress}} />}
      <div ref={ref}>
        {percent && (
          <Percent style={{'--progress': progress}}>
            {formatPercent(progress)}
          </Percent>
        )}
        <Text style={{'--progress': progress}}>
          {text}
        </Text>
      </div>
    </Placeholder>
  )
}
const Placeholder = styled.div`
  height: 200vh;
  margin-top: -100vh;
`
const appear = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  30% {
    opacity: 1;
    transform: scale(1.0);
  }
  70% {
    opacity: 1;
    transform: scale(1.0);
  }
  100% {
    opacity: 0;
    transform: scale(4.0);
  }
`
const percentage = keyframes`
  0% {
    opacity: 0;
    color: rgba(255, 255, 255, 0);
    text-shadow: 0 0 0 rgba(0,255,255,0);
    transform: scale(0.5);
  }
  30% {
    opacity: .5;
    color: rgba(255, 255, 255, 1);
    text-shadow: 0 0 5px rgba(0,255,255,0.5);
    transform: scale(1.0);
  }
  70% {
    opacity: .5;
    color: rgba(255, 255, 255, 1);
    text-shadow: 0 0 20px rgba(0,255,255,0.8);
    transform: scale(1.0);
  }
  80% {
    color: rgba(0,255,255,0.8);
  }
  90% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    color: rgba(255, 255, 255, 1);
    text-shadow: 0 0 50px rgba(0,255,255,1);
    transform: scale(4.0);
  }
`
const Text = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  inset: 0;
  animation: ${appear} 1s ease-in-out;
  animation-play-state: paused;
  animation-fill-mode: both;
  animation-delay: calc(var(--progress) * -1s);
  will-change: animation-delay;
  color: white;
  font-size: 50px;
`
const Percent = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  inset: 0;
  animation: ${percentage} 1s ease-in-out;
  animation-play-state: paused;
  animation-fill-mode: both;
  animation-delay: calc(var(--progress) * -1s);
  will-change: animation-delay;
  color: white;
  font-size: 250px;
  text-shadow: 0 0 1rem cyan;
`

const imageFade = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
`
const Image = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  animation: ${imageFade} 1s ease-in-out;
  animation-play-state: paused;
  animation-fill-mode: both;
  animation-delay: calc(var(--progress) * -1s);
  will-change: animation-delay;
  z-index: -1;
`
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${({color}) => color};
  animation: ${imageFade} 1s ease-in-out;
  animation-play-state: paused;
  animation-fill-mode: both;
  animation-delay: calc(var(--progress) * -1s);
  will-change: animation-delay;
  z-index: -1;
`