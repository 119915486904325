import React from 'react'
import styled from 'styled-components'
import Logo from '../assets/logo.svg'

export const HomeScreen = ({handleClick}) => {
  return (
    <Wrapper onClick={handleClick}>
      <img src={Logo} alt='MTIX Logo' />
    </Wrapper>
  )
}
const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 33vw;
  }
`