import React, { useState, useRef, useEffect } from "react";
import styled from 'styled-components';
import { Screen } from "./Screen";
import { HomeScreen } from "./HomeScreen";
import { scrollTo } from "../utilities/scrollTo";
import Cursor from '../assets/cursor.png'
import Background from '../assets/bg-pattern.png'
import Galaxy from '../assets/galaxy.svg'
import globe from '../assets/globe2.mov'
import fabric1 from '../assets/fabric1.png'
import fabric2 from '../assets/fabric2.png'
import { EndScreen } from "./EndScreen";

const data = [
  {text: "Authoring Infinite Futures", image: Galaxy, globeScale: 0, globeOpacity: 0},
  {text: "Investing in World Changing Technology", image: Galaxy, globeScale: 0.5, globeOpacity: 0.7},
  {text: "Like mlse® Technology", image: undefined, globeScale: 2, globeOpacity: 0.5},
  {text: "A Dramatic Leap Forward In Textile Processing", image: fabric1, globeScale: 2.5, globeOpacity: 0},
  {text: "To Revolutionize The Treatment Of Fabrics", image: fabric2, globeScale: 0, globeOpacity: 0},
  {text: "Greenhouse Gas Reduction", background: 'black', image: undefined, percent: 99.6, globeScale: 0, globeOpacity: 0},
  {text: "Chemical Resources Reduction", image: undefined, percent: 90.9, globeScale: 0, globeOpacity: 0},
  {text: "Energy Consumption Reduction", image: undefined, percent: 94.8, globeScale: 0, globeOpacity: 0},
  {text: "Water Consumption Reduction", image: undefined, percent: 75.5, globeScale: 0, globeOpacity: 0},
]

export const HomeScroller = () => {
  const [isClicked, setIsClicked] = useState(false)
  const wrapperRef = useRef(null)
  const globeRef = useRef(null)
  const [activeIndex, setActiveIndex] = useState(0)
  const [isScrolling, setIsScrolling] = useState(false);

  const scrollScreen = (e) => {
    e.preventDefault()
    if(!isClicked) {
      setIsClicked(true)
    } else {
      if(!isScrolling) {
        const pageHeight = window.innerHeight;
        const top = (activeIndex + 0.5) * pageHeight
        setIsScrolling(true)
        setTimeout(() => setIsScrolling(false), 3000)
        scrollTo(top, 3000);
      }
    }
    setActiveIndex(activeIndex + 1)
  }

  const handleActive = (index) => {
    if(!isScrolling) {
      setActiveIndex(index)
    }
  }

  const navigateAbout = (e) => {
    e.preventDefault();
    window.location = '/about'
  }

  useEffect(() => {
    if(isClicked) {
      const pageHeight = window.innerHeight;
      scrollTo(pageHeight / 2, 1500);
    }
  }, [isClicked])

  useEffect(() => {
    if(globeRef.current) {
      globeRef.current.playbackRate = 0.25;
    }
  })

  return (
    <Wrapper ref={wrapperRef} onClick={scrollScreen} isShowingScreens={isClicked}>
      <Bg src={Background} index={activeIndex} />
      {activeIndex <= data.length && (
        <GlobeWrapper>
          <GlobeVideo autoPlay={true} 
            loop={true}
            controls={false} 
            playsInline
            muted 
            src={globe}
            type="video/mp4" 
            scale={activeIndex > 0 ? data?.[activeIndex - 1]?.globeScale : 0}
            globeOpacity={activeIndex > 0 ? data?.[activeIndex - 1]?.globeOpacity : 0}
            ref={globeRef}
          />
        </GlobeWrapper>
      )}
      {!isClicked && <HomeScreen handleClick={() => setIsClicked(true)} />}
      {isClicked && data.map(({text, background, image, percent}, i) => <Screen key={text} text={text} background={background} image={image} percent={percent} handleClick={scrollScreen} index={i+1} setActive={handleActive} isLast={i+1 === data.length} />)}
      {activeIndex > data.length && (
        <EndScreen handleClick={navigateAbout} />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  padding-top: ${({isShowingScreens}) => isShowingScreens ? '200vh' : '0'};
  cursor: url("${Cursor}"), auto;
`
const Bg = styled.img`
  width: 100vw;
  height: 100vh;
  object-fit: fill;
  opacity: ${({index}) => 1 - (0.1 * index)};
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
`
const GlobeWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  `
const GlobeVideo = styled.video`
  mask-image: radial-gradient(circle at 50% 50%,rgba(0,0,0,0.8) 29%,rgba(0,0,0,0.6) 2%,rgba(0,0,0,0) 35%);
  transform: scale(${({scale}) => scale});
  filter: opacity(${({globeOpacity}) => globeOpacity});
  transition: transform 1500ms ease-in-out, opacity 1500ms ease-in-out;
  pointer-events: none;
`