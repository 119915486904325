import React from 'react'
import styled, {keyframes} from 'styled-components'
import Logo from '../assets/mlse.svg'
import Cursor from '../assets/arrow.png'
import Moon from '../assets/Moon.jpeg'

export const EndScreen = ({handleClick}) => {
  return (
    <Wrapper onClick={handleClick}>
      <Image src={Moon} alt='Moon' />
      <img src={Logo} alt='MLSE Logo' />
      <SubText>See How It Works</SubText>
    </Wrapper>
  )
}

const imageFade = keyframes`
  0% {
    opacity: 0;
    width: 0vw;
    height: 0vh;
  }
  100% {
    opacity: 1;
    width: 70vw;
    height: 70vh;
  }
`
const Image = styled.img`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
  animation: ${imageFade} 2s ease-in-out;
  animation-fill-mode: both;
  z-index: -1;
  max-width: none!important;
`
const SubText = styled.p`
  font-size: 36px;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`
const appear = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1.0);
  }
`
const Wrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  inset: 0;
  animation: ${appear} 1s ease-in-out;
  animation-fill-mode: both;
  color: white;
  font-size: 50px;
  cursor: url("${Cursor}"), auto;

  img {
    max-width: 33vw;
  }
`